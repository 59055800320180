<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3">
      <v-card class="radius-card px-3" :loading="loading">
        <div class="py-2"></div>
        <h3>Request Withdrawing Funds</h3>
        <div class="mt-3">
          <v-card class="text-center rounded-lg" outlined width="max-content">
            <div
              :class="
                `d-flex align-center pa-2 cursor ${
                  setStatus === 0 ? 'active' : ''
                }`
              "
              @click="
                setStatus = 0;
                fetchItems();
              "
            >
              <div class="unsend"></div>
              <p class="small_txt color_txt mb-0 ml-2">Pending</p>
            </div>
            <div
              :class="
                `d-flex align-center pa-2 cursor ${
                  setStatus === 1 ? 'active' : ''
                }`
              "
              @click="
                setStatus = 1;
                fetchItems();
              "
            >
              <div class="sent"></div>
              <p class="small_txt color_txt mb-0 ml-2">
                Terkonfirmasi, Menunggu di transfer
              </p>
            </div>
            <div
              :class="
                `d-flex align-center pa-2 cursor ${
                  setStatus === 2 ? 'active' : ''
                }`
              "
              @click="
                setStatus = 2;
                fetchItems();
              "
            >
              <div class="repair"></div>
              <p class="small_txt color_txt mb-0 ml-2">
                Ditransfer, Menunggu konfirmasi psikolog
              </p>
            </div>
            <div
              :class="
                `d-flex align-center pa-2 cursor ${
                  setStatus === 3 ? 'active' : ''
                }`
              "
              @click="
                setStatus = 3;
                fetchItems();
              "
            >
              <div class="confirm"></div>
              <p class="small_txt color_txt mb-0 ml-2">Terkonfirmasi</p>
            </div>
            <div
              :class="
                `d-flex align-center pa-2 cursor ${
                  setStatus === 4 ? 'active' : ''
                }`
              "
              @click="
                setStatus = 4;
                fetchItems();
              "
            >
              <div class="nofill"></div>
              <p class="small_txt color_txt mb-0 ml-2">Rejected</p>
            </div>
            <div
              v-if="setStatus !== ''"
              class="d-flex align-center pa-2 cursor"
              @click="
                setStatus = '';
                fetchItems();
              "
            >
              <div class="close"></div>
              <p class="small_txt color_txt mb-0 ml-2">Reset</p>
            </div>
          </v-card>
          <div class="mt-3" v-if="list">
            <v-data-table
              :headers="headers"
              :items="list.data"
              hide-default-footer
              :page.sync="page"
              :items-per-page="limit"
              class="elevation-0 my-5"
            >
              <template v-slot:[`item.psycholog`]="{ item }">
                <div class="d-flex">
                  <v-btn depressed class="mr-2" @click.prevent="viewPsy(item)">
                    <p class="ma-0 small_txt">
                      {{ item.psycholog.nama_lengkap }}
                    </p>
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`item.status_withdraw`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      :class="
                        item.status_withdraw == 0
                          ? 'unsend'
                          : item.status_withdraw == 1
                          ? 'sent'
                          : item.status_withdraw == 2
                          ? 'repair'
                          : item.status_withdraw == 3
                          ? 'confirm'
                          : 'nofill'
                      "
                    ></div>
                  </template>
                  <span v-if="item.status_withdraw == 0">Pending</span>
                  <span v-if="item.status_withdraw == 1"
                    >Terkonfirmasi, Menunggu di transfer</span
                  >
                  <span v-if="item.status_withdraw == 2"
                    >Ditransfer, Menunggu konfirmasi psikolog</span
                  >
                  <span v-if="item.status_withdraw == 3">Terkonfirmasi</span>
                  <span v-if="item.status_withdraw == 4">Rejected</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.request`]="{ item }">
                <p class="ma-0 small_txt color_txt">
                  {{ item.status_withdraw_at }}
                </p>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  depressed
                  color="red"
                  dark
                  v-if="item.status_withdraw == 4"
                >
                  Rejected
                </v-btn>
                <v-btn
                  depressed
                  color="#64ccb7"
                  dark
                  v-else-if="item.status_withdraw == 3"
                >
                  Done
                </v-btn>
                <v-btn
                  v-else
                  dark
                  depressed
                  color="cyan"
                  class="mr-2"
                  style="min-width: 10px"
                  @click.prevent="updateItem(item)"
                >
                  <v-icon dark small> mdi-cog </v-icon>
                </v-btn>
              </template>
            </v-data-table>

            <v-pagination
              v-model="page"
              circle
              @input="fetchItems"
              :length="list.last_page"
            ></v-pagination>
            <v-row>
              <v-col cols="2">
                <p class="mb-0">Tampilkan</p>
                <div class="d-flex align-center">
                  <v-select
                    :items="listLimit"
                    v-model="limit"
                    dense
                    hide-details
                    outlined
                    @change="fetchItems"
                  ></v-select>
                  <p class="ml-1 mb-0">Data</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="pa-2">
          <ProfilePsy
            :selected="dataUser"
            :d_profilePsy="d_profilePsy"
            @close="d_profilePsy = false"
          />
          <change-status-withdraw
            :dialogAlert="dialogAlert"
            @close="dialogAlert = false"
            @refetch="fetchItems"
            :selected="dataUser"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import ChangeStatusWithdraw from "../BATCH3/Counseling/Admin/changeStatusWithdraw.vue";
import ProfilePsy from "../BATCH3/Counseling/Admin/profilePsy.vue";
export default {
  components: { ChangeStatusWithdraw, ProfilePsy },
  name: "requestTrx",
  data() {
    return {
      list: null,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "NAME",
          align: "start",
          value: "psycholog",
        },
        {
          text: "AMOUNT",
          align: "start",
          value: "amount",
        },
        {
          text: "REQUEST DATE",
          align: "start",
          value: "request",
        },
        {
          text: "STATUS",
          align: "start",
          value: "status_withdraw",
        },
        {
          text: "ACTIONS",
          align: "start",
          value: "actions",
        },
      ],
      page: 1,
      limit: 10,
      listLimit: [5, 10, 20, 50, 70, 100],
      dialogAlert: false,
      tab: "tab-1",
      dataUser: null,
      d_profilePsy: false,
      d_profileClient: false,
      d_evidence: false,
      setStatus: "",
      loading: false,
    };
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      this.loading = true;
      let data = {
        page: this.page,
        limit: this.limit,
        status: this.setStatus,
      };
      this.$store.dispatch("counseling/listWithdraw", data).then((data) => {
        this.list = data;
        this.loading = false;
      });
    },
    viewPsy(item) {
      this.dataUser = item.psycholog;
      this.d_profilePsy = true;
    },
    updateItem(item) {
      this.dataUser = item;
      this.dialogAlert = true;
    },
  },
};
</script>

<style scoped>
.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}
.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}
.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}
.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}
.active {
  background: #f3e5f5;
}
.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}
.close {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #fff;
  margin-right: 10px;
}
.bagi3 {
  width: calc(100% / 3);
}
</style>
