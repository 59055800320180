<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAlert" persistent max-width="370px">
      <v-card class="radius-card hide_overflow" flat>
        <div class="d-flex justify-end px-2">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon></v-btn
          >
        </div>
        <div
          class="d-flex flex-column align-center justify-center pa-3"
          style="min-height: 230px"
          v-if="selected"
        >
          <div v-if="!isReject">
            <div
              class="d-flex justify-center"
              v-if="selected.status_withdraw == 0"
            >
              <v-btn
                @click="changeItem(1)"
                class="text-capitalize py-6 mr-4"
                dark
                outlined
                color="purple"
                width="100px"
                depressed
                >Accept</v-btn
              >
              <v-btn
                class="text-capitalize py-6"
                @click="isReject = true"
                outlined
                color="purple"
                width="100px"
                depressed
                >Reject</v-btn
              >
            </div>
            <div
              class="d-flex justify-center align-center flex-column"
              v-if="selected.status_withdraw == 1"
            >
              <p class="ma-0 small_txt color_txt text-center mb-3">
                konfirmasi bahwa dana yang di minta sudah di transfer
              </p>
              <v-btn
                @click="changeItem(2)"
                class="text-capitalize py-6 mr-4"
                dark
                outlined
                color="purple"
                width="100px"
                depressed
                >Confirm</v-btn
              >
            </div>
            <div
              class="d-flex justify-center"
              v-if="selected.status_withdraw == 2"
            >
              menunggu konfirmasi psikolog
            </div>
          </div>
          <div v-if="isReject">
            <v-textarea
              v-model="reason"
              placeholder="Alasan Reject"
              rows="3"
            ></v-textarea>
            <d-flex>
              <v-btn
                class="text-capitalize py-6 mr-3"
                @click="isReject = false"
                outlined
                color="purple"
                width="100px"
                depressed
                >Back</v-btn
              >
              <v-btn
                class="text-capitalize py-6"
                @click="changeItem(4)"
                outlined
                color="purple"
                width="100px"
                depressed
                >Reject</v-btn
              >
            </d-flex>
          </div>
          <v-overlay
            :absolute="true"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="purple"
            ></v-progress-circular>
            <b class="purple--text">Loading...</b>
          </v-overlay>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "withdrawChange",
  props: ["dialogAlert", "selected"],

  data() {
    return {
      role: "",
      loading: false,
      reason: "",
      isReject: false
    };
  },
  methods: {
    changeItem(state) {
      this.loading = true;
      let body = {
        id: this.selected.id,
        status_withdraw: state,
        status_withdraw_reason: this.reason
      };
      this.$store
        .dispatch("counseling/switchStatusWithdraw", body)
        .then(data => {
          this.$emit("close");
          this.$emit("refetch");
          this.loading = false;
          this.isReject = false;
          Swal.fire({
            title: data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            toast: true
          });
        })
        .catch(err => {
          this.loading = false;

          console.log(err);
        });
    }
  }
};
</script>
